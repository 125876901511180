@import (less) "mixins.less";

ul#menu-mobile-navigation{
    width:100%;
    background:@blueDark;
    padding:0;
    margin:0;
    display:none;
    >li{
        display:block;
        .centered();
        overflow:hidden;
        ul.sub-menu{
        	height:0;
        	>li{
        	background:@grayLight;
        	>a{
        		font-size:15px;
        		color:@blueDark;
        		border-bottom:1px solid #fff;
        		opacity:0;
        		}
        	}
        	}
        a{
            display:block;
            width:100%;
            padding:15px;
            .uppercase();
            .opensans(18px);
            color:#fff;
            .bold();
            border-bottom:1px solid @grayLight;
            &:hover{
                }
            }
        }
    }
    
a#menu-toggle{
    color:#fff;
    font-size:24px;
    position:absolute;
    top:10px;
    right:10px;
    display:none;
    z-index:500;
    &.active{
        }
    }


@media only screen 
and (max-width : 1300px) {
	#solutions-section{
		>.solution-item{
			h6{
				font-size:16px;
				}
			.icon{
				svg{
					max-width:110px;
					}
				}
			}
		}

	}
	
@media only screen 
and (max-width : 1000px) {
	#solutions-section{
		>.solution-item{
			h6{
				font-size:14px;
				}
			.icon{
				svg{
					max-width:100px;
					}
				}
			}
		}
	}

@media only screen 
and (max-width : 900px) {
    ul#menu-main-navigation{
        li{
            a{font-size:12px;}
            }
        }
    }
    
@media only screen 
and (max-width : 800px) {
   a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation{display:none;}
   
    }
 
@media only screen 
and (max-width : 768px) {
	br.clear{
		display:none;
		}
	#main-header{
		.logo-wrap{
			padding-top:30px!important;
			.centered();
			img{
				.inline-block();
				max-width:240px;
				}
			}
		.nav-wrap{
			.top-box{
				display:none;
				}
			}
		}
	#video-banner{
		height:auto!important;
		padding-top:120px;
		padding-bottom:80px;
		video#banner-video{
			display:none;
			}
		#banner-slide-wrap{
			height:auto;
			overflow:hidden;
			}
		.video-banner-item{
			>div{
				p{
					padding:20px;
					}
				}
			}
		.video-banner-item{
			height:auto!important;
			padding:0 30px 0 30px;
			font-size:15px;
			>div{
				top:0;
				transform:translateY(0);
				}
			h1{
				font-size:30px;
				font-size:1.2;
				padding-bottom: 10px;
				margin-bottom: 10px;
				span{
					font-size:40px;
					}
				}
			}
		}
	#solutions-section{
		>.solution-item{
			border-left: none!important;
			border-right:none!important;
			border-bottom:1px solid #fff;
			h6{
				font-size:18px;
				}
			.icon{
				svg{
					max-width:130px;
					}
				}
			}
		}
	#why-section{
		.left{
			.centered();
			padding:0 10px 0 10px!important;
			border:none;
			}
		}
	#sectors-section{
		
		}
	.sector-items-wrap{
			border-top:1px solid #fff;
			>.sector-item{
				border-left: none!important;
				border-right:none!important;
				border-top:none!important;
				border-bottom:1px solid #fff;
				&:last-child{border:none;}
				}
			}
	#clients-section{
		.clients-content-wrap{
			padding:60px 0 60px 0;
			.triangle{
				display:none;
				}
			}
		#clients-gallery-wrap{
			padding:50px 0 50px 0;
			max-width:100%!important;
		.client-logo-item{
				img{
				max-height:80%;
				width:auto!important;
				}
			button{
				}
			}
		}
	}
	#testimonials-section{
		.right{
			padding:10px!important;
			border:none;
			.centered();
			img{
				max-width:80%;
				}
			}
		}
	#blog-section{
		.blog-item{
			margin-bottom: 25px;
			.centered();
			.item-content-wrap{
				.inline-block();
				}
			}
		}
	#footer{
		.footer-content{
			padding:30px 0 30px 0;
			.right, .left{
				padding:5px 25px 5px 25px;
				}
			img.footer-logo{
				max-width:240px;
				margin-bottom:20px;
				}
			}
		.gform_wrapper, #searchform{
			input[type=text]{
				font-size:15px;
				}
			button{
				top:5px!important;
				}
			.gform_footer{
				top:9px!important;
				}
			}
			ul#menu-social-navigation-1{
				padding:5px 0 5px 0!important;
				}
		}
	#banner{
		h1{
			font-size:40px!important;
			line-height:1.2!important;
			}
		}
	#main-content{
		padding:0 10px 0 10px!important;
		.callout{font-size:18px;}
		&.right{
			border:none;
			
			}
		}
	#sidebar{
		padding-left:0!important;
		border:none!important;
		margin-top:30px;
		}
	.column-section{
	padding:80px 0 80px 0;
	ul{
		 -webkit-column-count: 1; /* Chrome, Safari, Opera */
        -moz-column-count: 1; /* Firefox */
        column-count: 1;
        li{
        	.centered();
        	i{
        		position:relative;
        		left:0;
        		top:0;
        		.inline-block();
        		margin-right:10px;
        		}
        	}
       
		}
}
	.section{
		h5.section-head{
			font-size:32px;
			}
		}	
	#clients-section{
		overflow:hidden;
		}
	#blog-section{
		.watermark{
			display:none;
			}
		}
	#banner{
		padding:100px 0 60px 0;
		h1{
			font-size:42px;
			}
		}
		.toggler{
			.trigger{
				font-size:15px;
				height:auto!important;
				line-height: 1.2;
				min-height:70px;
				padding:15px 0 15px 90px;
				}
			.toggle_container{
				padding-left:35px;
				}
			}
	#management{
		.small-icon-item{
			.centered();
			img{
				.inline-block();
				margin:0 0 15px 0;
				}
			.text{
				display:block;
				}
			}
		.right{
			.text{
				.centered();
				border:none!important;
				padding:20px 10px 0 10px!important;
				margin-bottom:15px;
				}
			.callout{
				padding:0;
				}
			}
		}
	.filter-form{
		select{
			.inline-block();
			width:100%!important;
			}
		.grid-33{
			margin:10px 0 10px 0;
			}
		}
	.filter-menu-wrap{
		.filter-menu-toggle, .filter-menu{
			display:none!important;
			
			}
		
		}
	#namesearch{
		display:none;
		}
	.hidden-select{
				display:block!important;
				}
	#map-toggle,#map-wrap{display:none!important;}
	#fundraising{
	padding:80px 0 80px 0;
	ul{
		 -webkit-column-count: 1; /* Chrome, Safari, Opera */
        -moz-column-count: 1; /* Firefox */
        column-count: 1;
        li{
        	.centered();
        	i{
        		position:relative;
        		left:0;
        		top:0;
        		.inline-block();
        		margin-right:10px;
        		}
        	}
		}
	}
	#engagement-options .option-item{
		max-width:100%;
		margin:0 0px 30px 0;
		}
	#partner-details{
		.centered();
		padding:0 10px 0 10px;
		.widget{
			h5.widget-title{
				.centered();
				}
			}
		ul{
			li{
        	.centered();
        	padding:0 0 0 0!important;
        	i{
        		position:relative;
        		left:0;
        		top:2px;
        		.inline-block();
        		margin-right:10px;
        		}
        	}
			}
		}
	.testimonial-slider-item{
		.left{
			margin-bottom:30px;
			}
		&.single{
			.testimonial-full{
				padding:0 10px 0 10px!important;
				.quote{
					display:none!important;
					}
				}
			}
		}
	.directorship-item{
		.centered();
		.alignleft{
			float:none!important;
			.inline-block();
			margin:0 0 20px 0;
			}
		}
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    }
   
@media only screen 
and (max-width : 480px) {

.testimonial-slider-item{
	a.video-button{
		i{
			font-size:40px;
			}
		}
	}


}

@media only screen 
and (max-height : 600px) {
	.video-banner-item{
			
			
			h1{
				font-size:40px;
				font-size:1.2;
				padding-bottom: 10px;
				margin-bottom: 10px;
				span{
					font-size:50px;
					}
				}
			}
	}